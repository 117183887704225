;(function () {

"use strict";

require('../shaders/ConvolutionShader');
require('../shaders/CopyShader');
require('../shaders/FXAAShader');
require('../shaders/RGBShiftShader');
require('../shaders/DotScreenShader');
require('../postprocessing/EffectComposer');
require('../postprocessing/MaskPass');
require('../postprocessing/RenderPass');
require('../postprocessing/ShaderPass');
require('../postprocessing/BloomPass');

var MastView = require('./mast');

var ServicesMastView = MastView.extend({
  initialize: function (opts) {
    MastView.prototype.initialize.call(this, opts);

    this.initSphere();
    this.initRenderer();

    this.activate();
  },
  initSphere: function () {
    this.camera = new THREE.PerspectiveCamera( 80, this.w / this.h, 1, 3000 );
    this.camera.position.z = 1200;

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2( 0x000000, 0.0009 );

    var i, line, vertex1, vertex2, material, p, geometry, object, mesh;

    this.parameters = [
      // [ 0.001, 0xff7700, 0.95, 20 ],
      [ 0.2, 0xff9900, 0.5, 3.5 ],
      [ 0.3, 0xffaa00, 0.5, 2.25 ],
      [ 0.5, 0xffaa00, 0.5, 1.20 ],
      [ 0.75, 0xffaa00, 0.1, 0.05 ],
    ];

    material = new THREE.MeshPhongMaterial( { color: 0xffffff, shading: THREE.FlatShading, wireframe: false, opacity: 0.30 } );

    this.objects = [];

    geometry = new THREE.IcosahedronGeometry( 15, 0 );
    object = new THREE.Object3D();
    mesh = new THREE.Mesh( geometry, material );
    mesh.position.set( 0,0,0  ).normalize();
    mesh.position.multiplyScalar( 0 * 140 );
    mesh.rotation.set( Math.random() * 2, Math.random() * 2, Math.random() * 2 );
    mesh.scale.x = mesh.scale.y = mesh.scale.z = 43;
    object.add( mesh );
    this.scene.add( object );
    this.objects.push(object);

    this.scene.add( new THREE.AmbientLight( 0x000000 ) );
    var light = new THREE.DirectionalLight( 0xffffff );
    light.position.set( 10, 50, 10 );
    this.scene.add( light );

  },
  initRenderer: function () {
    this.renderer = new THREE.WebGLRenderer( { alpha: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.w, this.h );
    this.renderer.autoClear = false;
    this.$container.append( this.renderer.domElement );

    this.composer = new THREE.EffectComposer( this.renderer );
    this.composer.addPass( new THREE.RenderPass( this.scene, this.camera ) );

    var effect;
    effect = new THREE.ShaderPass( THREE.DotScreenShader );
    effect.uniforms.scale.value = 0.75;
    this.composer.addPass( effect );

    effect = new THREE.ShaderPass( THREE.RGBShiftShader );
    effect.uniforms.amount.value = 0.008;
    effect.renderToScreen = true;
    this.composer.addPass( effect );
  },
  render: function () {
    this.camera.position.y += ( - this.mouseY - this.camera.position.y ) * 0.01;
    this.camera.lookAt( this.scene.position );

    var time = Date.now() * 0.0001;

    this.objects.forEach(function (object, idx) {
      if (idx % 2 === 0) {
        object.rotation.y += 0.0025; 
        object.rotation.z += 0.0025; 
        object.rotation.x += 0.0025; 
      } else {
        object.rotation.y -= 0.0025;
        object.rotation.z += 0.0025; 
        object.rotation.x += 0.0025; 
      }
    });

    /* Aurora */
    var delta = this.clock.getDelta();
    this.uniforms.time.value += delta * 5;

    this.renderer.clear();
    this.renderer.render( this.scene, this.camera );
    this.composer.render();

    this.renderer.render( this.bgScene, this.bgCam );
    this.renderer.clearDepth();
  }
});

module.exports = ServicesMastView;

}());