;(function () {

"use strict";

require('../shaders/ConvolutionShader');
require('../shaders/CopyShader');
require('../shaders/FXAAShader');
require('../shaders/RGBShiftShader');
require('../shaders/DotScreenShader');
require('../postprocessing/EffectComposer');
require('../postprocessing/MaskPass');
require('../postprocessing/RenderPass');
require('../postprocessing/ShaderPass');
require('../postprocessing/BloomPass');

var MastView = require('./mast');

var prev = Date.now();

var DevMastView = MastView.extend({
  initialize: function (opts) {
    opts.half = true;
    MastView.prototype.initialize.call(this, opts);

    this.initRenderer();

    _.delay(this.activate.bind(this), 100);
  },
  initRenderer: function () {
    this.renderer = new THREE.WebGLRenderer({alpha: true});
    this.renderer.autoClear = false;
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.w, this.h );
    this.$container.append( this.renderer.domElement );

    this.composer = new THREE.EffectComposer( this.renderer );
    this.composer.addPass( new THREE.RenderPass( this.bgScene, this.bgCam ) );

    var effect;
    effect = new THREE.ShaderPass( THREE.DotScreenShader );
    effect.uniforms.scale.value = 2;
    effect.uniforms.angle.value = 10.57;

    this.composer.addPass( effect );
    effect.renderToScreen = true;
  },
  render: function () {
    /* Aurora */
    var delta = this.clock.getDelta();
    this.uniforms.time.value += delta * 5;

    this.renderer.clear();
    this.composer.render();
    this.renderer.render( this.bgScene, this.bgCam );
    this.renderer.clearDepth();
  }
});

module.exports = DevMastView;

}());