;(function () {

"use strict";

var EvenView = Backbone.View.extend({
  initialize: function () {
    console.log('init even view');

    this.guid = App.guid();
    this.$children = this.$el.children();

    $(window).on('resize.'+this.guid, _.debounce(_.bind(this.resize, this)));
    this.resize();
    this.$children.addClass('resized');
  },
  resize: function () {
    var h, max = -100;

    this.$children.css('height', '');

    this.$children.each(function () {
      h = $(this).height();

      if (h > max) {
        max = h;
      }
    });

    this.$children.height(max);
  },
  destroy: function () {
    $(window).off('resize.'+this.guid);
  }
});

module.exports = EvenView;

}());