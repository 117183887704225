;(function () {

var PageView = require('./page');

/*Move This*/
var explode = function (string) {
  var html = '';

  string.split('').forEach(function (char) {
    html += "<span>"+char+"</span>";
  });

  return html;
};

var clamp = function (num, min, max) {
  return Math.min(Math.max(num, min), max);  
};

var HeroPageView = PageView.extend({
  initialize: function (opts) {
    PageView.prototype.initialize.call(this, opts);

    var self = this;

    setTimeout(function () {
      self.showCopy();
    }, 1000);
  },
  showCopy: function () {
    var $this, html, delay, len = 0;
    var $copy = this.$el.find('.mast .content h3');

    $copy.each(function () {
      $this = $(this);
      html = explode($this.text());
      $this.html(html);
    });

    $copy.each(function (i) {
      $this = $(this);
      delay = 0;

      $this.children().each(function (j) {
        $this = $(this);

        if (j % 2) {
          delay = clamp(Math.random(), 0.1, 1) * 400;
        } else {
          delay = clamp(Math.random(), 0.1, .7) * 400;
        }
        // delay = 10 * j;

        var show = function ($el) {
          return function () { $el.addClass('active'); };
        }($this);

        setTimeout( show, (500 * i) + delay );
      });

      len += $(this).children().length;
    });

    $copy.addClass('active');
  }
});

module.exports = HeroPageView;

}());