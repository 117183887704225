;(function () {

"use strict";

/* Is Homepage flag */
window.landing = true;

var DataHelper = {
  getPage: function () {
    if (this.request) this.request.abort();
    var url = '/'+Backbone.history.getFragment();
    this.request = $.get(url);
    return this.request;
  }
};

/* App Router */
var AppRouter = Backbone.Router.extend({
  initialize: function () {
    console.log('initialized router');
    this.bind('all', this._trackPageview);

    /* Create the Google Analytics Tracker */
    // ga('create', 'UA-56064167-1', 'auto');
    // ga('require', 'displayfeatures');
  },
  _trackPageView: function (title) {
    /* TODO: Integrate with GA */
    var opts = {};
    opts.page = Backbone.history.getFragment();
    opts.location = window.location.pathname;

    if (title) {
      opts.title = title;
    }

    ga('send', 'pageview', opts);
  },
  routes: {
    "*all": "render",
  },
  render: function () {
    if (window.landing) {
      window.landing = false;
      this._trackPageView();
      return;
    }


    window.App.timer = Date.now();
    $('body').addClass('loading');

    var self = this;
    DataHelper.getPage().done(function (html) {
      var $html = $('<div>').html(html);
      var $content = $html.find("#content");
      var $page = $html.find("#page");

      /* Meta */
      var title = $html.find('title').html();
      var description = $html.find('meta[name="description"]').attr('content');
      var keywords = $html.find('meta[name="keywords"]').attr('content');

      /* SEO */
      $('title').html(title);
      $('meta[name="description"]').attr('content', description);
      $('meta[name="keywords"]').attr('content', keywords);

      window.App.render($content, $page);

      self._trackPageView(title);
    }).fail(function (html) {
      /* Break if aborted */
      if (html.statusText === "abort") { return; }

      /* Otherwise render 404 or 500 html */
      var $html = $('<div>').html(html.responseText);
      var $content = $html.find("#content");
      var $page = $html.find("#page");

      /* Meta */
      var title = $html.find('title').html();
      var description = $html.find('meta[name="description"]').attr('content');
      var keywords = $html.find('meta[name="keywords"]').attr('content');

      /* SEO */
      $('title').html(title);
      $('meta[name="description"]').attr('content', description);
      $('meta[name="keywords"]').attr('content', keywords);

      window.App.render($content, $page);

      self._trackPageView(title);
    });
  }
});


module.exports = AppRouter;

}());