;(function () {

"use strict";

var Instafeed = require('instafeed.js');

var token = "2228584941.3ac1628.dce09f9d7bb04c688b60d8e85f5da02c";

var InstagramView = Backbone.View.extend({
  initialize: function () {
    var url = window.location.protocol + "//api.instagram.com/v1/users/6360851/media/recent?access_token="+token;

    var feed = new Instafeed({
      get: 'user',
      userId: '11427426',
      accessToken: token,
      target: this.$el[0],
      limit: 5,
      resolution: 'standard_resolution',
      template: '<li class="siv"><a target="_blank" href="{{link}}"><img title="{{caption}}" alt="{{caption}}" src="{{image}}"/></a></li>'
    });

    feed.run();
  }
});

module.exports = InstagramView;

}());