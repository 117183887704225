;(function () {

"use strict";

var PageView = Backbone.View.extend({
  initialize: function (opts) {
    this.views = [];
  },
  destroy: function () {
    this.views.forEach(function (view) {
      if (view.destroy) {
        view.destroy();
      }
    });

    /* Unbind View */
    this.undelegateEvents();
    this.$el.removeData().unbind();
  }
});

module.exports = PageView;

}());