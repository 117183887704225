;(function () {

"use strict";

var HeaderView = Backbone.View.extend({
  initialize: function () {
    this.$el.find('ul.toggles').children().each(function () {
      console.log(this);
    });

    $(window).on('scroll.header', _.throttle(_.bind(this.scroll, this), 100));

    this.$el.animate({opacity: 1}, 2000);
  },
  scroll: function () {
    // console.log('scrolling');
  },
  close: function () {
    $('.header, .header .toggle').removeClass('active');
  },
  toggle: function (e) {
    // var $this = $(e.currentTarget);
    // $this.toggleClass('active');
    this.$el.toggleClass('active');

    /* Hack for the 2 headers */
    $('.header .toggle').toggleClass('active');
  },
  events: {
    'click .toggle': 'toggle'
  }
});

module.exports = HeaderView;

}());