;(function () {

"use strict";

var MastView = require('./mast');

var AboutMastView = MastView.extend({
  initialize: function (opts) {
    MastView.prototype.initialize.call(this, opts);

    this.initStars(_.bind(function () {
      this.initRenderer();
      this.activate();
    }, this));
  },
  initStars: function (cb) {
    this.camera = new THREE.PerspectiveCamera( 80, this.w / this.h, 1, 3000 );
    this.camera.position.z = 520;

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2( 0x000000, 0.0009 );

    this.parameters = [
      [ 0.2, 0xff9900, 0.5, 3.5 ],
      [ 0.3, 0xffaa00, 0.5, 2.25 ],
      [ 0.5, 0xffaa00, 0.5, 1.20 ],
      [ 0.75, 0xffaa00, 0.1, 0.05 ]
    ];

    var geometry = this.createGeometry();
    var texture = new THREE.TextureLoader().load( '/images/texture-0.png' , _.bind(function (texture) {

      var i, line, vertex1, vertex2, material, p;
      for( i = 0; i < this.parameters.length; ++ i ) {

        p = this.parameters[ i ];

        material = new THREE.PointsMaterial( { color: 0xeeeeff, vertexColors: THREE.VertexColors, map: texture, transparent: true });
        material.blending = THREE.CustomBlending;
        material.blendSrc = THREE.SrcAlphaFactor;
        material.blendDst = THREE.OneFactor;

        line = new THREE.Points( geometry, material );
        line.scale.x = line.scale.y = line.scale.z = p[ 0 ];
        line.originalScale = p[ 0 ];
        line.rotation.y = Math.random() * Math.PI;
        line.updateMatrix();
        this.scene.add( line );
      }

      if (cb) {
        cb();
      }
    }, this));
  },
  createGeometry: function () {
    var i, r = 500;
    var geometry = new THREE.Geometry();
    var colors = [];
    var len = 2000;

    if (window.App.touch) {
      len = 500;
    }

    for ( i = 0; i < len; i ++ ) {

      var vertex = new THREE.Vector3();
      vertex.x = Math.random() * 2000 - 1000;
      vertex.y = Math.random() * 2000 - 1000;
      vertex.z = Math.random() * 2000 - 1000;

      vertex.normalize();
      vertex.multiplyScalar( r + (Math.random() * 380) );

      geometry.vertices.push( vertex );

      var c = new THREE.Color( 0xffaaff );
      var hsl = c.getHSL();
      var h;

      if (Math.random() > 0.5) {
        h = hsl.h + (Math.random() * 0.5);
      } else {
        h = hsl.h - (Math.random() * 0.5);
      }
      c.setHSL(h, hsl.s, hsl.l);
      colors.push(c);
    }

    geometry.colors = colors;

    return geometry;    
  },
  initRenderer: function () {
    this.renderer = new THREE.WebGLRenderer( { alpha: true, antialiased: true } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.w, this.h );
    this.renderer.autoClear = false;
    this.$container.append( this.renderer.domElement );
  },
  render: function () {
    this.camera.position.y += ( - this.mouseY - this.camera.position.y ) * 0.01;
    this.camera.lookAt( this.scene.position );

    var time = Date.now() * 0.0001;

    for ( var i = 0; i < this.scene.children.length; i ++ ) {

      var object = this.scene.children[ i ];

      if ( object instanceof THREE.Points ) {

        object.rotation.y = time * ( i < 4 ? ( i + 1 ) * 0.5 : - ( i + 1 ) * 0.5 );
        object.scale.x = object.scale.y = object.scale.z = object.originalScale * (1 + ((1/(i+1))) * Math.sin( 4*time ) );
      }
    }

    /* Aurora */
    var delta = this.clock.getDelta();
    this.uniforms.time.value += delta * 5;

    this.renderer.clear();
    this.renderer.render( this.scene, this.camera );
    this.renderer.render( this.bgScene, this.bgCam );
    this.renderer.clearDepth();
  }
});

module.exports = AboutMastView;

}());