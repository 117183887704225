;(function () {

"use strict";

var HeroView = require('./hero');
var DevMastView = require('../views/dev-mast');

var ServiceDetailPageView = HeroView.extend({
  initialize: function (opts) {
    HeroView.prototype.initialize.call(this, opts);
    this.views.push(new DevMastView({ el: this.$el.find('.mast') }));
  }
});

module.exports = ServiceDetailPageView;

}());