;(function () {

"use strict";

require('./config-client');

var AppRouter = require('./router');

var HeaderView = require('./views/header');
var GlobalView = require('./views/global');

/* Switch this to bulkify */
var Pages = {
  'index': require('./pages/index'),
  'about': require('./pages/about'),
  'services': require('./pages/services'),
  'service-detail': require('./pages/service-detail')
};

window.App.scroll = [0];

$(document).ready(function () {
  window.zoom = $('html').css('zoom') || 1;

  /* Keep global zoom var */
  $(window).on('resize', function () {
    window.zoom = $('html').css('zoom') || 1;
  });

  /* Initialize the Backbone Router */
  window.App.router = new AppRouter();
  Backbone.history.start({pushState: true});

  /* Global & Header Never Reinitialize */
  window.App.hv = new HeaderView({ el: $('.header') });
  window.App.gv = new GlobalView({ el: $('body') });

  window.App.initPage();
});

window.App.render = function ($content, $page) {
  window.App.hv.close();

  /* Replace this with faster solution */
  setTimeout(_.bind(function () {
    if ($content && $page) {
      $('#content').replaceWith($content);
      $("#page").replaceWith($page);

      if (window.App.saveScroll) {
        $(window).scrollTop(window.App.prevScrollTop || 0);
        window.App.prevScrollTop = 0;
        window.App.saveScroll = undefined;
      } else {
        $(window).scrollTop(0);      
      }
    }

    $('body').removeClass('loading');

    _.defer(_.bind(function () {
      this.initPage();
      $(window).trigger('scroll');
    }, this));
  }, this), 500);
};

window.App.initPage = function ($content) {
  if (window.App.pv) {
    window.App.pv.destroy();
  }

  window.App.gv.reinitialize();
  var PageView = Pages[$('#page').attr('content')];

  if (PageView) {

    window.App.pv = new PageView({ el: $('body') });
  }

};

}());