;(function () {

"use strict";

var ContactUsFormView = require('./contact-us-form');
var EvenView = require('./even');

var GlobalView = Backbone.View.extend({
  initialize: function () {
    this.views = [];

    window.onload = _.bind(function () {
      $(window).on('scroll.global', _.throttle(_.bind(this.scroll, this), 50));
      $(window).trigger('scroll');
    }, this);

    setTimeout(function () {
      if ($(window).scrollTop() === 0) {
        $(window).trigger('scroll');        
      }
    }, 4000);

    this.$header = $('.header');
    this.reinitialize();
  },
  reinitialize: function () {
    this.views.forEach(function (view) {
      if (view.destroy) {
        view.destroy();
      }
    });

    this.views = [];
    var self = this;

    $('.contact-us').each(function () {
      self.views.push(new ContactUsFormView({ el: this }));
    });

    $('ul.even').each(function () {
      self.views.push(new EvenView({el: this}));
    });

    this.delegateEvents();
  },
  show: function ($this) {
    $this.addClass('visible');
    setTimeout(function () { 
      $this.removeClass('visible')
           .removeClass('siv');
    }, 500);
  },
  scroll: function () {

    /* Need to fix this. Something up with st calculation */

    var i = 0; var j = 0;
    var st = $("#horizon-line").offset().top;
    var atBottom = ($(window).scrollTop() + $(window).height())  / window.zoom >= $(document).height();
    var $header = this.$header;
    var self = this;

    console.log(st);

    this.$el.find('.siv').each(function () {
      var $this = $(this);

      console.log($this.offset().top);

      /* Hack for zoom- look into it */
      if( $this.offset().top < st || atBottom ) {
        
        /* Don't animate if half way down the page */        
        if (($this.offset().top + $this.height()) - $header.offset().top < 0) {

          setTimeout(function () {
            self.show($this);
          });

        } else {
          setTimeout(function () {
            self.show($this) ;
          }, 50 + (150 * i++));
        }
      }
    });
  },
  scrollToNextSection: function (e) {
    var st = $(e.currentTarget).offset().top;
    $('html,body').animate({'scrollTop': st}, 750);
  },
  events: {
    'click .scroller': 'scrollToNextSection',
    'click a:not(a.outside-link)': function (e) {
      var $this = $(e.currentTarget);

      if ($this.attr('target') === '_blank') { return; }

      if ($this.data('save-scroll')) {
        window.App.saveScroll = true;
      } else {
        window.App.prevScrollTop = $(window).scrollTop();
      }

      var href = $(e.currentTarget).attr('href').replace(window.location.origin, '');
      window.App.router.navigate(href, {trigger: true});
      e.preventDefault();
    }
  }
});

module.exports = GlobalView;

}());