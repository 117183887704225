;(function () {

"use strict";

var MastView = require('./mast');

var prev = Date.now();

var IndexMastView = MastView.extend({
  initialize: function (opts) {
    MastView.prototype.initialize.call(this, opts);

    this.initStars(_.bind(function () {
      this.initRenderer();
      this.activate();
    }, this));
  },
  initRenderer: function () {
    this.renderer = new THREE.WebGLRenderer({alpha: true});
    this.renderer.autoClear = false;
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( this.w, this.h );
    this.$container.append( this.renderer.domElement );
  },
  initStars: function (cb) {
    this.camera = new THREE.PerspectiveCamera( 75, this.w / this.h, 1, 3000 );
    this.camera.position.z = 800;

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.FogExp2( 0x000000, 0.00009 );

    this.parameters = [
      [ [10, 1, 0.9], 4 ],
      [ [10, 1, 0.7], 3 ],
      [ [10, 1, 0.6], 2 ],
      [ [10, 1, 1], 1 ],
      [ [10, 1, 1], 0.5 ]
    ];

    var i, j = 0;
    var texture = new THREE.TextureLoader().load( '/images/texture-0.png', _.bind(function (texture) {

      this.geometries = [];
      this.materials = [];

      var geometry, color, size, particles, stars;
      for ( i = 0; i < this.parameters.length; ++i ) {

        geometry = new THREE.Geometry();
        stars = window.App.touch ? 500 : 1000;

        for ( j = 0; j < stars; j ++ ) {
          var vertex = new THREE.Vector3();
          vertex.x = Math.random() * 2000 - 1000;
          vertex.y = Math.random() * 2000 - 1000;
          vertex.z = Math.random() * 2000 - 1000;
          geometry.vertices.push( vertex );
        }

        this.geometries.push(geometry);

        color = this.parameters[i][0];
        size  = this.parameters[i][1];

        this.materials[i] = new THREE.PointsMaterial( { size: size, map: texture, transparent: true } );
        this.materials[i].blending = THREE.CustomBlending;
        this.materials[i].blendSrc = THREE.SrcAlphaFactor;
        this.materials[i].blendDst = THREE.OneFactor;

        particles = new THREE.Points( this.geometries[i], this.materials[i] );
        this.scene.add( particles );
      }

      if (cb) {
        cb();
      }
    }, this));

  },
  render: function () {
    var i;
    var time = (Date.now() - prev) * 0.0009;

    this.camera.position.x += ( this.mouseX - this.camera.position.x ) * 0.001;
    this.camera.position.y += ( - this.mouseY - this.camera.position.y ) * 0.001;
    this.camera.lookAt( this.scene.position );

    for ( i = 0; i < this.scene.children.length; i ++ ) {
      var object = this.scene.children[ i ];

      if ( object instanceof THREE.Points ) {
        object.rotation.y = time * ( i < 4 ? 0.015 : - ( 0.015 ) );
        object.rotation.z = time * ( i < 4 ? 0.015 : - ( 0.015 ) );
        object.rotation.x = time * ( i < 4 ? 0.015 : - ( 0.015 ) );
      }
    }

    for ( i = 0; i < this.materials.length; i ++ ) {
      var color = this.parameters[i][0];
      var h = ( ( color[0] + ( (time * i * 5) % 360 ) % 360 ) ) / 360;

      /* Flicker */
      if (Math.random () < 0.005) {
        this.materials[i].color.setHSL( h, color[1], color[2] + 0.01 );
      } else {
        this.materials[i].color.setHSL( h, color[1], color[2] );
      }
    }

    /* Aurora */
    var delta = this.clock.getDelta();
    this.uniforms.time.value += delta * 5;

    this.renderer.clear();
    this.renderer.render( this.scene, this.camera );
    this.renderer.render( this.bgScene, this.bgCam );
    this.renderer.clearDepth();
  }
});

module.exports = IndexMastView;

}());