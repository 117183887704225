;(function () {

"use strict";

var MastView = Backbone.View.extend({
  initialize: function (opts) {
    opts = opts || {};
    this.half = opts.half;

    this.w = $(window).width() / window.zoom;
    this.h = $(window).height() / window.zoom;

    if (this.half) {
      this.h = this.h / 2;
    }

    this.mouseX = 0;
    this.mouseY = 0;
    this.center = { x: this.w / 2, y: this.h/2 };

    this.$container = $('<div>');
    this.$el.append( this.$container );

    this.clock = new THREE.Clock();

    this.initAurora();

    this.guid = window.App.guid();
    $(document).on('mousemove.'+this.guid, _.bind(this.mouseMove, this));
    $(window).on('resize.'+this.guid, _.throttle(_.bind(this.resize, this), 100));
  },
  activate: function () {
    setTimeout(function () {
      $('canvas').addClass('active');
    }, 0);
    this.animate();
  },
  initAurora: function () {
    /* Add aurora */
    var geometry = new THREE.PlaneBufferGeometry( 7000, 4000 );

    this.uniforms = {
      time: { type: "f", value: 65.0 },
      resolution: { type: "v2", value: new THREE.Vector2() }
    };

    var params = [ 'fragment_shader1', this.uniforms ];

    var material = new THREE.ShaderMaterial( {
      uniforms: params[ 1 ],
      vertexShader: document.getElementById( 'vertexShader' ).textContent,
      fragmentShader: document.getElementById( params[ 0 ] ).textContent,
      transparent: true,
    });

    var bg = new THREE.Mesh( geometry, material );
    bg.material.depthTest = false;
    bg.material.depthWrite = false;

    this.bgScene = new THREE.Scene();
    this.bgCam = new THREE.PerspectiveCamera( 75, this.w / this.h, 1, 3000 );
    this.bgCam.position.z = 800;
    this.bgScene.add(this.bgCam);
    this.bgScene.add(bg);    
  },
  resize: function () {
    var zoom = window.zoom;
    this.w = $(window).width() / zoom;
    this.h = $(window).height() / zoom;

    this.center.x = this.w / 2;
    this.center.y = this.h / 2;

    if (this.half) {
      this.h = this.h / 2;
      this.center.y = this.center.y / 2;
    }

    if (this.camera) {
      this.camera.aspect = this.w / this.h;
      this.camera.updateProjectionMatrix();
    }

    if (this.bgCam) {
      this.bgCam.aspect = this.w / this.h;
      this.bgCam.updateProjectionMatrix();
    }

    if (this.renderer) {
      this.renderer.setSize( this.w, this.h );    
    }
  },
  mouseMove: function (e) {
    this.mouseX = e.clientX - this.center.x;
    this.mouseY = e.clientY - this.center.y;
  },
  animate: function () {
    this.animationId = window.requestAnimationFrame( _.bind(this.animate, this) );
    this.render();
  },
  destroy: function () {
    window.cancelAnimationFrame(this.animationId);
    $(window).off('resize.'+this.guid);
    $(document).off('mousemove.'+this.guid);
  }

});

module.exports = MastView;

}());