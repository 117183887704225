;(function () {

"use strict";

var HeroPageView = require('./hero');
var AboutMastView = require('../views/about-mast');

var PageView = HeroPageView.extend({
  initialize: function (opts) {
    HeroPageView.prototype.initialize.call(this, opts);
    this.views.push( new AboutMastView({el: this.$el.find('.mast') }));
  }
});

module.exports = PageView;

}());