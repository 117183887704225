;(function () {

var HeroPageView = require('./hero');
var IndexMastView = require('../views/index-mast');
var InstagramView = require('../views/instagram');

var PageView = HeroPageView.extend({
  initialize: function (opts) {
    HeroPageView.prototype.initialize.call(this, opts);
    this.views.push(new IndexMastView({ el : this.$el.find(".mast") }));

    var self = this;
    this.$el.find('.instagram').each(function () {
      self.views.push( new InstagramView({ el: this }) );
    });
  }
});

module.exports = PageView;

}());