;(function () {

"use strict";

var validEmail = function (email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

var invalidate = function ($el) {
  $el.parent().addClass('invalid');
};

var ContactUsFormView = Backbone.View.extend({
  initialize: function () {
    console.log('init form view');
    console.log(this.el);
  },
  submit: function (e) {
    e.preventDefault();

    this.reset();

    if (this.validate()) {
      var self = this;
      $.post('/contact', this.$el.serialize(), function (d) {
        if (d.status === 'success') {
          self.$el.addClass('submitted');
          var h = self.$el.find('.thanks').outerHeight();
          self.$el.height(h);
        } else {
          alert('Something went wrong, please try again later.');
        }
      });
    }
  },
  reset: function () {
    this.$el.find('input, textarea').each(function () {
      $(this).parent().removeClass('invalid');
    });
  },
  validate: function () {
    var valid = true;

    var $name = this.$el.find('input[name="name"]');
    if ( !$name.val() ) {
      valid = false;
      invalidate($name);
    }

    var $email = this.$el.find('input[name="email"]');
    if ( !$email.val() || !validEmail( $email.val()) ) {
      valid = false;
      invalidate($email);
    }

    var $textarea = this.$el.find('textarea');
    if ( !$textarea.val() ) {
      valid = false;
      invalidate($textarea);
    }

    return valid;
  },
  events: {
    'submit': 'submit'
  }
});

module.exports = ContactUsFormView;

}());