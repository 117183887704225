;(function () {

"use strict";

/* Globals */
window.jQuery = window.$ = require('jquery');
window._ = require('underscore');
window.Backbone = require('backbone');
window.THREE = require('three');
window.Stats = require('stats.js');

/* Setup Global App Object */
window.App = {};
window.App.touch = (function () {
  return 'ontouchstart' in window || navigator.maxTouchPoints;
}());

window.App.guid = (function () {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
               .toString(16)
               .substring(1);
  }
  return function() {
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
           s4() + '-' + s4() + s4() + s4();
  };
}());

}());