;(function () {

"use strict";

var HeroPageView = require('./hero');
var ServicesMastView = require('../views/services-mast');

var PageView = HeroPageView.extend({
  initialize: function (opts) {
    HeroPageView.prototype.initialize.call(this, opts);
    this.views.push(new ServicesMastView({el: this.$el.find('.mast') }));
  }
});

module.exports = PageView;

}());